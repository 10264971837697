export type AnimationProperties = {
  property: string,
  dur: number,
  loop: boolean,
  from: string,
  to?: string
};

export const IdleAnimation: string = "property: rotation; to: 0 360 0; loop: true; dur: 8000; easing: linear; pauseEvents: mouseenter; resumeEvents: mouseleave";
export const FocusedAnimationOpacity: string = "property: components.material.material.opacity; from: .3; to: 1; loop: true; dur: 500; dir: alternate; easing: easeInOutQuad";
export const FocusedAnimationScale: string = "property: scale; from: 0.44 0.06 0.44; to: 0.627 0.06 0.627; loop: true; dur: 500; dir: alternate; easing: easeInOutQuad";
