import React from "react";
import { Entity } from "aframe-react";
import { Interactable } from "../../../interfaces/Interactable";
import "./TextBox.scss";
import "aframe-html-shader";
import closeIcon from "../../../assets/images/close-icon.png";
import TextModel from "../../../assets/models/text.glb";
import BaseIcon from "../BaseIcon/BaseIcon";

interface TextBoxProps extends Interactable {content: string, header: string, colour: string};
interface TextBoxState {open: boolean};

class TextBox extends React.Component<TextBoxProps, TextBoxState> {
  state: TextBoxState = {
    open: false
  };

  private onTextIconClicked = () => {
    // TODO: Not all text boxes should do this
    this.props.onInteract(this.props.id, "BRIGHTENSCENE");
    this.setState(prevState => ({
      open: !prevState.open
    }));
  }

  private onCloseClicked = () => {
    this.setState(prevState => ({
      open: !prevState.open
    }));
  }

  public render() {
    const open = this.state.open;
    return (
      <>
        {open
          ? <Entity position={this.props.position}>
            <div id="textBoxHtmlElement" >
              <div id="textBoxHeader" style={{ background: this.props.colour + "aa" }}>{this.props.header}</div>
              <div id ="closeButton" style={{ background: this.props.colour + "aa" }}>
                <img id="closeIcon" src={closeIcon} />
              </div>
              <div id="textBoxContent">{this.props.content}</div>
            </div>

            <Entity
              look-at="[camera]"
              scale="7 7 7"
              geometry="primitive: plane; width: 0.6; height: 0.25;"
              material="shader: html; transparent: true; target: #textBoxHtmlElement">
              <Entity
                class="interactive"
                position="0.278 0.100 -0.005"
                material="opacity: 0.0"
                geometry="primitive: plane; width: 0.05; height: 0.05;"
                events={{
                  click: this.onCloseClicked
                }} />
            </Entity>
          </Entity>
          : <BaseIcon {...this.props} modelSrc={TextModel} onClick={this.onTextIconClicked} />
        }
      </>);
  }
}
export { TextBox }; export type { TextBoxProps };
