import React from "react";
import { Entity } from "aframe-react";

interface ICameraProps {
  offsetRot?: number;
  offsetPos?: string;
}

const Camera = ({ offsetPos, offsetRot }: ICameraProps) => {
  const rotOffset = offsetRot ?? "0";
  let camera = document.getElementById("camera");

  const onMouseWheelEvent = (e: WheelEvent) => {
    const el = document.elementFromPoint(e.clientX, e.clientY);
    if (camera !== null && el.className.includes("a-canvas")) {
      const delta = Math.sign(e.deltaY);
      const mycam = camera.getAttribute("camera");
      /* eslint-disable */
      /*// @ts-ignore */
      let finalZoom: any = (mycam.fov - (delta * -2));
      if (finalZoom < 40) {
        finalZoom = 40;
      }
      if (finalZoom > 100) {
       finalZoom = 100;
      }
      /*// @ts-ignore */
      mycam.fov = finalZoom;
      camera.setAttribute('camera', mycam);
    }
  };

  React.useEffect(() => {
    camera = document.getElementById("camera");
    window.addEventListener("wheel", WheelEvent => {
      onMouseWheelEvent(WheelEvent);
    });
  }, []);

  return (
    <Entity id="camParent" rotation={ "0 " + rotOffset + " 0" } position={offsetPos}>
      <Entity id="camera" primitive="a-camera"/>
    </Entity>
  );
}

export default Camera;
