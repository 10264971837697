/* eslint-disable */
// @ts-nocheck

if (!String.prototype.format) {
  String.prototype.format = function() {
      var args = arguments;
      return this.replace(/{(\d+)}/g, function(match, number) {
          return typeof args[number] != 'undefined'
              ? args[number]
              : match
              ;
      });
  };
}

AFRAME.registerSystem("preloader", {
  schema: {
    id: { type: "string", default: "preloader-modal" },
    target: { type: "selector", default: "#preloader-modal" },
    label: { type: "selector", default: "#preloader-modal .progress-label" },
    debug: { type: "boolean", default: false }
  },

  multiple: false,
  loadedAssetCount: 0,
  totalAssetCount: 0,

  init: function() {
    document.querySelector("a-assets").addEventListener("loaded", function() {
      this.triggerProgressComplete();
    }.bind(this));

    const assetItems = document.querySelectorAll("a-assets a-asset-item,a-assets img,a-assets audio,a-assets video");
    this.totalAssetCount = assetItems.length;
    this.watchPreloadProgress(assetItems);
    this.initBootstrapModal($(this.data.target));
  },

  update: function(oldData) { },

  watchPreloadProgress: function(assetItems) {
    for (let a = 0; a < assetItems.length; a++) {
      let eventName;

      switch (assetItems[a].nodeName) {
      case "A-ASSET-ITEM":
        eventName = "loaded";
        break;
      case "IMG":
        eventName = "load";
        break;
      case "audio":
      case "VIDEO":
        eventName = "loadeddata";
        break;
      default:
        if(this.data.debug) console.info("No name set for node " + assetItems[a].nodeName);
        break;
      }

      assetItems[a].addEventListener(eventName, function(e) {
        this.loadedAssetCount++;
        if (this.data.debug) {
          console.info("Loaded " + this.loadedAssetCount + "/" + this.totalAssetCount + " asset items");
        }
        this.onAssetLoaded();
      }.bind(this));
    }
  },

  onAssetLoaded: function() {
    if (this.loadedAssetCount === this.totalAssetCount) {
      this.triggerProgressComplete();
    } else {
      const percentage = Math.floor(this.loadedAssetCount / this.totalAssetCount * 100);
      this.drawProgress(percentage);
    }
  },

  triggerProgressComplete: function() {
    this.drawProgress(100);
    this.data.target.classList.add("preloader-modal__complete");
    this.triggerPreloadingComplete();
    this.closeModal();
  },

  drawProgress: function(percentage) {
    this.data.label.innerHTML = `Loading: ${percentage}%`;
  },

  initBootstrapModal: function($modal) {
    $modal.modal({
      backdrop: "static",
      keyboard: false
    });
  },

  triggerPreloadingComplete: function() {
    this.el.emit("preloading-complete");
  },

  closeModal: function() {
    $(this.data.target).modal("hide");
  }
});
