import React from "react";
import { Entity } from "aframe-react";
import { Interactable } from "../../../interfaces/Interactable";

interface ModelProps extends Interactable { modelSrc: string, rotation: string, scale: string};

const Model = ({ ...props }: ModelProps) =>
  <Entity
    position={props.position}
    rotation={props.rotation}
    scale={props.scale}
    gltf-model={props.modelSrc}
  />;
;

export { Model }; export type { ModelProps };
