import React from "react";
import "./../../../aframe-components/video-icon";
import "./VideoIcon.scss";
import { Interactable } from "../../../interfaces/Interactable";
import VideoPlayer from "./VideoPlayer";
import BaseIcon from "../BaseIcon/BaseIcon";
import VideoModel from "../../../assets/models/video.glb";

interface VideoIconProps extends Interactable {
  content: string,
  header: string,
  colour: string,
  src: string,
};

interface VideoIconState {open: boolean};

class VideoIcon extends React.Component<VideoIconProps> {
  videoPlayer: React.RefObject<VideoPlayer> = React.createRef<VideoPlayer>();

  state: VideoIconState = {
    open: false
  };

  handleVideoIconClick = () => {
    this.props.onInteract(this.props.id);
    this.videoPlayer.current.openPlayer(this.props.src);
  }

  public render() {
    return (
      <>
        <BaseIcon
          key={this.props.header}
          {...this.props} modelSrc={VideoModel} onClick={this.handleVideoIconClick} />
        <VideoPlayer
          colour={this.props.colour}
          header={this.props.header}
          content={this.props.content}
          ref={this.videoPlayer}/>
      </>
    );
  }
}

export default VideoIcon;
export type { VideoIconProps };
