import React from "react";
import "./intro.scss";
import Camera from "../../components/AFrame/Camera/Camera";
import VideoSphere from "../../components/AFrame/VideoSphere/VideoSphere";
import PlayButton from "../../assets/images/video-icons/play.png";
import logo from "../../assets/images/branding/logo_large.png";
import logo2 from "../../assets/images/branding/logo_hei.png";
import { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router";
import VideoPlayerTest from "../../components/AFrame/VideoIcon/VideoPlayerTest";
import ReactPlayer from "react-player";

interface IntroProps extends RouteComponentProps {};
type IntroState = {
  videoSrc: string,
  showInstructions: boolean,
  videoPlaying: boolean
};

class Intro extends React.Component<IntroProps, IntroState> {
  videoPlayer: React.RefObject<VideoPlayerTest> = React.createRef<VideoPlayerTest>();

  componentDidMount() {
    const visited = localStorage.alreadyVisited;
    if (visited) {
      this.setState({ showInstructions: false });
    } else {
      localStorage.alreadyVisited = true;
      this.setState({ showInstructions: true });
    };
  }

  state: IntroState = {
    videoSrc: "#introBlur",
    showInstructions: true,
    videoPlaying: false
  };

  private playIntro = () => {
    const introEl: HTMLElement = document.getElementById("playButtonContainer");
    const startButtonEl: HTMLElement = document.getElementById("startButtonContainer");
    introEl.style.display = "none";
    startButtonEl.style.display = "block";

    this.setState({ videoSrc: "#intro" }, () => {
      const video: Element = document.getElementById("intro");
      // @ts-ignore
      video.play();
      this.setState({ videoPlaying: true });
    });
  }

  private startTour = () => {
    this.setState({ videoSrc: null });
    // @ts-ignore
    document.querySelectorAll("video").forEach(vid => { vid.currentTime = 0; });
    // @ts-ignore
    document.querySelectorAll("video").forEach(vid => vid.pause());
    this.setState({ videoSrc: "#introBlur" });
    if (this.state.showInstructions) {
      this.videoPlayer.current.openPlayer("https://vimeo.com/577720402/4c37a33d22");
    } else {
      this.props.history.push("/tour");
    }
  }

  private startTourTest = () => {
    this.props.history.push("/tour");
  }

  render() {
    return (
      <>
        <div id="logoContainer">
          <img id="logo" src={logo} />
        </div>
        <div id="logoContainer2">
          <img id="logo2" src={logo2} />
        </div>
        <div id="playButtonContainer">
          <button id="playButton" onClick={this.playIntro}><img src={PlayButton} /></button>
        </div>
        <div id="startButtonContainer">
          <button id="startButton" onClick={this.startTour}>Start</button>
        </div>
        <Camera />
        <VideoSphere src={this.state.videoSrc} radius={500} />
        <VideoPlayerTest
          onDissmiss={this.startTourTest}
          header={"Navigation Instruction Video"}
          content={"Please watch this video to learn how to navigative and explore this 360 Virtual Tour. You can access this video anytime by clicking the Show help button on the top right of the screen"}
          colour={"#30a3dc"}
          src={"https://vimeo.com/577720402/4c37a33d22"}
          ref={this.videoPlayer}/>
        <div id="videoBorderSubtitle">
          <ReactPlayer
            playing={this.state.videoPlaying}
            controls={false}
            className='reactPlayerIntro'
            url={"https://vimeo.com/582078676/301d42957c"}
            width="50%"
            height="100%"
            style={{ pointerEvents: "none" }}
            volume={0}
            muted
          />
        </div>
      </>
    );
  }
}

export default withRouter(Intro);
