import React from "react";
import { Entity } from "aframe-react";
import { Interactable } from "../../../interfaces/Interactable";
import "./ImageCarousel.scss";
import "aframe-html-shader";
import closeIcon from "../../../assets/images/close-icon.png";
import ImageCarouselModel from "../../../assets/models/imageCarousel.glb";
import Arrow from "../../../assets/models/arrow.glb";
import BaseIcon from "../BaseIcon/BaseIcon";

interface ImageCarouselProps extends Interactable {
  content: string,
  header: string,
  imgArray: string[],
  colour: string
};
interface ImageCarouselState {open: boolean, openImage: number};

class ImageCarousel extends React.Component<ImageCarouselProps, ImageCarouselState> {
  state: ImageCarouselState = {
    open: false,
    openImage: 0
  };

  private onIconClicked = () => {
    this.props.onInteract(this.props.id);
    this.setState(prevState => ({
      open: !prevState.open
    }));
  }

  private handleNextClick = () => {
    if (this.state.openImage < this.props.imgArray.length - 1) {
      this.setState((prevState) => ({
        openImage: prevState.openImage + 1
      }));
    }
  }

  private handlePreviousClick = () => {
    if (this.state.openImage > 0) {
      this.setState((prevState) => ({
        openImage: prevState.openImage - 1
      }));
    }
  }

  public render() {
    const open = this.state.open;
    return (
      <>
        {open
          ? <Entity position={this.props.position}>
            <div id="htmlElement" >
              <div id="imageContainer" style={{ background: this.props.colour + "aa" }}></div>
              <div id ="closeButton" style={{ background: this.props.colour + "aa" }}>
                <img id="closeIcon" src={closeIcon} />
              </div>
              <div id="imageDisplayHeader" style={{ background: this.props.colour + "aa" }}>{this.props.header}</div>
              <div id="imageDisplayContent">{this.props.content}</div>
            </div>

            <Entity
              look-at="[camera]"
              scale="5 5 5"
              geometry="primitive: plane; width: .9; height: .7;"
              material="shader: html; transparent: true; target: #htmlElement">
              <Entity
                primitive="a-image"
                src={this.props.imgArray[this.state.openImage]}
                width="1.6"
                height="0.9"
                scale=".5 .5 .5"
                position="-0.036 0.103 0.005"
              />

              <Entity
                class="interactive"
                position="-0.53 .02 0"
                scale=".07 .07 .07"
                rotation="0 90 0"
                event-set__1="_event: mouseup; scale: .07 .07 .07"
                event-set__2="_event: mouseenter; scale: .08 .08 .08"
                event-set__3="_event: mousedown; scale: .08 .08 .08"
                event-set__4="_event: mouseleave; scale: .07 .07 .07"
                gltf-model={Arrow}
                events={{
                  click: this.handlePreviousClick
                }}/>

              <Entity
                class="interactive"
                position="0.47 .02 0"
                scale=".07 .07 .07"
                rotation="0 -90 0"
                event-set__1="_event: mouseup; scale: .07 .07 .07"
                event-set__2="_event: mouseenter; scale: .08 .08 .08"
                event-set__3="_event: mousedown; scale: .08 .08 .08"
                event-set__4="_event: mouseleave; scale: .07 .07 .07"
                gltf-model={Arrow}
                events={{
                  click: this.handleNextClick
                }} />

              <Entity
                class="interactive"
                position="0.420 0.311 -0.005"
                material="opacity: 0.0"
                geometry="primitive: plane; width: 0.05; height: 0.05;"
                events={{ click: this.onIconClicked }} />
            </Entity>
          </Entity>
          : <BaseIcon {...this.props} modelSrc={ImageCarouselModel} onClick={this.onIconClicked} />
        }
      </>);
  }
}
export { ImageCarousel }; export type { ImageCarouselProps };
