import React from "react";
import tourData from "../../interfaces/tourData";
import { mapProps } from "./mapProps";

export default function CanteenMap({ activeRoom, colour, onClick }: mapProps) {
  const [hoveredPart, setHoveredPart] = React.useState<string>(null);
  const getOpacity = (part: string): string => {
    if (activeRoom === part) {
      return "0.75";
    } else if (hoveredPart === part) {
      return "0.55";
    } else {
      return "0.08";
    }
  };

  return (
    <svg id="Canteen" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 470 468">
      <g className="map">
        <g>
          <rect
            onMouseEnter={() => setHoveredPart("Seating Area - Front")}
            onMouseLeave={() => setHoveredPart(null)}
            onMouseDown={() => onClick(tourData.scenes[2])}
            cursor={"pointer"}
            fill={colour}
            opacity={getOpacity("Seating Area - Front")} x="17" y="11" width="147" height="347"/>
          <rect
            onMouseEnter={() => setHoveredPart("Canteen Entrance")}
            onMouseLeave={() => setHoveredPart(null)}
            onMouseDown={() => onClick(tourData.scenes[1])}
            cursor={"pointer"}
            fill={colour}
            opacity={getOpacity("Canteen Entrance")} x="109" y="358" width="194" height="95"/>
          <rect
            onMouseEnter={() => setHoveredPart("Deli")}
            onMouseLeave={() => setHoveredPart(null)}
            onMouseDown={() => onClick(tourData.scenes[4])}
            cursor={"pointer"}
            fill={colour}
            opacity={getOpacity("Deli")} x="165" y="11" width="187" height="125"/>
          <rect
            onMouseEnter={() => setHoveredPart("Seating Area - Side")}
            onMouseLeave={() => setHoveredPart(null)}
            onMouseDown={() => onClick(tourData.scenes[3])}
            cursor={"pointer"}
            fill={colour}
            opacity={getOpacity("Seating Area - Side")} x="165" y="228" width="186" height="130"/>
          <rect
            onMouseEnter={() => setHoveredPart("Seating Area - Back")}
            onMouseLeave={() => setHoveredPart(null)}
            onMouseDown={() => onClick(tourData.scenes[5])}
            cursor={"pointer"}
            fill={colour}
            opacity={getOpacity("Seating Area - Back")} x="351" y="11" width="96" height="347"/>
        </g>
        <text pointerEvents={"none"} fill="#fff" fontSize="28px" fontWeight="700" fontFamily="Arial" transform="translate(230.57 85.82)">Deli</text>
        <text pointerEvents={"none"} fill="#fff" fontSize="28px" fontWeight="700" fontFamily="Arial" transform="translate(99.26 300) rotate(-90)">Seating Area Front</text>
        <text pointerEvents={"none"} fill="#fff" fontSize="28px" fontWeight="700" fontFamily="Arial" transform="translate(205.68 290.14)">Seating<tspan x="21" y="33.6">Side</tspan></text>
        <text pointerEvents={"none"} fill="#fff" fontSize="28px" fontWeight="700" fontFamily="Arial" transform="translate(410.5 300) rotate(-90)">Seating Area Back</text>
        <text pointerEvents={"none"} fill="#fff" fontSize="28px" fontWeight="700" fontFamily="Arial" transform="translate(147.09 426.3)">Entrance</text>
        <rect pointerEvents={"none"} fill="none" stroke="#fff" strokeWidth="5px" strokeMiterlimit="10" x="17.5" y="11.5" width="430" height="347"/>
        <line pointerEvents={"none"} fill="none" stroke="#fff" strokeWidth="5px" strokeMiterlimit="10" x1="164.5" y1="11" x2="164.5" y2="358"/>
        <line pointerEvents={"none"} fill="none" stroke="#fff" strokeWidth="5px" strokeMiterlimit="10" x1="351.5" y1="11" x2="351.5" y2="358"/>
        <line pointerEvents={"none"} fill="none" stroke="#fff" strokeWidth="5px" strokeMiterlimit="10" x1="165" y1="136.5" x2="352" y2="136.5"/>
        <line pointerEvents={"none"} fill="none" stroke="#fff" strokeWidth="5px" strokeMiterlimit="10" x1="165" y1="228.5" x2="352" y2="228.5"/>
        <rect pointerEvents={"none"} fill="none" stroke="#fff" strokeWidth="5px" strokeMiterlimit="10" x="109.5" y="358.5" width="194" height="95"/>
      </g>
    </svg>
  );
}
