import React from "react";
import tourData from "../../interfaces/tourData";
import { mapProps } from "./mapProps";

export default function StudentAreaMap({ activeRoom, colour, onClick }: mapProps) {
  const [hoveredPart, setHoveredPart] = React.useState<string>(null);
  const getOpacity = (part: string): string => {
    if (activeRoom === part) {
      return "0.75";
    } else if (hoveredPart === part) {
      return "0.55";
    } else {
      return "0.08";
    }
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 10 510 421">
      <g className="map">
        <g>
          <rect
            onMouseEnter={() => setHoveredPart("Hallway")}
            onMouseLeave={() => setHoveredPart(null)}
            onMouseDown={() => onClick(tourData.scenes[12])}
            cursor={"pointer"}
            fill={colour}
            opacity={getOpacity("Hallway")} x="18" y="27" width="419" height="107"/>

          <rect
            onMouseEnter={() => setHoveredPart("Entrance")}
            onMouseLeave={() => setHoveredPart(null)}
            onMouseDown={() => onClick(tourData.scenes[9])}
            cursor={"pointer"}
            fill={colour}
            opacity={getOpacity("Entrance")} x="17" y="134" width="210" height="233"/>

          <rect
            onMouseEnter={() => setHoveredPart("Foyer Seating")}
            onMouseLeave={() => setHoveredPart(null)}
            onMouseDown={() => onClick(tourData.scenes[10])}
            cursor={"pointer"}
            fill={colour}
            opacity={getOpacity("Foyer Seating")} x="227" y="134" width="97" height="233"/>

          <rect
            onMouseEnter={() => setHoveredPart("Foyer")}
            onMouseLeave={() => setHoveredPart(null)}
            onMouseDown={() => onClick(tourData.scenes[11])}
            cursor={"pointer"}
            fill={colour}
            opacity={getOpacity("Foyer")} x="324" y="134" width="113" height="233"/>

          <rect
            onMouseEnter={() => setHoveredPart("Entrance Hallway")}
            onMouseLeave={() => setHoveredPart(null)}
            onMouseDown={() => onClick(tourData.scenes[8])}
            cursor={"pointer"}
            fill={colour}
            opacity={getOpacity("Entrance Hallway")}x="18" y="367" width="209" height="131"/>

          <rect
            onMouseEnter={() => setHoveredPart("Help Desk")}
            onMouseLeave={() => setHoveredPart(null)}
            onMouseDown={() => onClick(tourData.scenes[13])}
            cursor={"pointer"}
            fill={colour}
            opacity={getOpacity("Help Desk")} x="324" y="368" width="160" height="131"/>

          <rect
            onMouseEnter={() => setHoveredPart("SU Offices")}
            onMouseLeave={() => setHoveredPart(null)}
            onMouseDown={() => onClick(tourData.scenes[14])}
            cursor={"pointer"}
            fill={colour}
            opacity={getOpacity("SU Offices")} x="484" y="368" width="161" height="130"/>

          <text pointerEvents={"none"} fill="#fff" fontSize="28px" fontWeight="700" fontFamily="Arial" transform="translate(175.28 91.66)">Hallway</text>
          <text pointerEvents={"none"} fill="#fff" fontSize="28px" fontWeight="700" fontFamily="Arial" transform="translate(60.98 258.98)">Entrance</text>
          <text pointerEvents={"none"} fill="#fff" fontSize="28px" fontWeight="700" fontFamily="Arial" transform="translate(392.13 289.04) rotate(-90)">Foyer</text>
          <text pointerEvents={"none"} fill="#fff" fontSize="28px" fontWeight="700" fontFamily="Arial" transform="translate(284.16 343.49) rotate(-90)">Foyer Seating</text>
          <text pointerEvents={"none"} fill="#fff" fontSize="28px" fontWeight="700" fontFamily="Arial" transform="translate(59.42 428.23)">Entrance<tspan x="7.77" y="33.6">Hallway</tspan></text>
          <text pointerEvents={"none"} fill="#fff" fontSize="28px" fontWeight="700" fontFamily="Arial" transform="translate(373.79 425.23)">Help<tspan x="-3.13" y="33.6">Desk</tspan></text>
          <text pointerEvents={"none"} fill="#fff" fontSize="28px" fontWeight="700" fontFamily="Arial" transform="translate(546.3 425.23)">SU<tspan x="-28.01" y="33.6">Offices</tspan></text>

          <rect fill="none" stroke="#fff" strokeWidth="5px" strokeMiterlimit="10" x="17.5" y="26.5" width="420" height="341"/>
          <rect fill="none" stroke="#fff" strokeWidth="5px" strokeMiterlimit="10" x="17.5" y="367.5" width="209" height="130"/>
          <rect fill="none" stroke="#fff" strokeWidth="5px" strokeMiterlimit="10" x="323.5" y="367.5" width="322" height="130"/>
          <line fill="none" stroke="#fff" strokeWidth="5px" strokeMiterlimit="10" x1="484.5" y1="499" x2="484.5" y2="368"/>
          <line fill="none" stroke="#fff" strokeWidth="5px" strokeMiterlimit="10" x1="17" y1="133.5" x2="437" y2="133.5"/>
          <line fill="none" stroke="#fff" strokeWidth="5px" strokeMiterlimit="10" x1="226.5" y1="368" x2="226.5" y2="134"/>
          <line fill="none" stroke="#fff" strokeWidth="5px" strokeMiterlimit="10" x1="323.5" y1="371" x2="323.5" y2="134"/>
        </g>
      </g>
    </svg>

  );
}
