import React from "react";
import { Entity } from "aframe-react";
import { BrightenScene } from "../../../effects";

const EffectsSphere = (): JSX.Element => <Entity
  id="EffectsSphere"
  primitive="a-sphere"
  position="0 0 0"
  scale="2 2 2"
  material="depthWrite: false; opacity: 0; side: back; blending: additive"
  animation={"property: opacity; from: 0; to: .4; loop: 1; dir: alternate; dur: 5000; startEvents: " + BrightenScene}
/>;

export default EffectsSphere;
