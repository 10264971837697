import React from "react";
import { mapProps } from "./mapProps";
import tourData from "../../interfaces/tourData";

export default function LectureHallMap({ activeRoom, colour, onClick }: mapProps) {
  const [hoveredPart, setHoveredPart] = React.useState<string>(null);
  const getOpacity = (part: string): string => {
    if (activeRoom === part) {
      return "0.75";
    } else if (hoveredPart === part) {
      return "0.55";
    } else {
      return "0.08";
    }
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="10 -150 450 490">
      <g className="map">
        <g>
          <rect
            onMouseEnter={() => setHoveredPart("Seating Area - Back")}
            onMouseLeave={() => setHoveredPart(null)}
            onMouseDown={() => onClick(tourData.scenes[7])}
            cursor={"pointer"}
            fill={colour}
            opacity={getOpacity("Seating Area - Back")} x="41.5" y="49.5" width="228" height="114"/>
          <rect
            onMouseEnter={() => setHoveredPart("Seating Area - Front")}
            onMouseLeave={() => setHoveredPart(null)}
            onMouseDown={() => onClick(tourData.scenes[6])}
            cursor={"pointer"}
            fill={colour}
            opacity={getOpacity("Seating Area - Front")} x="41" y="163" width="228" height="114"/>

          <rect fill="none" stroke="#fff" strokeWidth="5px" strokeMiterlimit="10" x="41.5" y="49.5" width="227" height="228"/>
          <line fill="none" stroke="#fff" strokeWidth="5px" strokeMiterlimit="10" x1="42" y1="163.5" x2="269" y2="163.5"/>

          <text pointerEvents={"none"} fill="#fff" fontSize="28px" fontWeight="700" fontFamily="Arial" transform="translate(110.95 95)">Upper<tspan x="-10" y="40">Seating</tspan></text>
          <text pointerEvents={"none"} fill="#fff" fontSize="28px" fontWeight="700" fontFamily="Arial" transform="translate(119.65 213.33)">Front<tspan x="-10" y="40">Seating</tspan></text>
        </g>
      </g>
    </svg>
  );
}
