import { TooltipHost } from "@fluentui/react";
import React from "react";
import { ISection, ITourScene } from "../../interfaces/tour";
import "./InteractionMarker.scss";

interface InteractionMarkProps {
  section: ISection;
  onClick: (scene: ITourScene, position: string) => void;
  focusedMark: string;
}

const InteractionMarker = ({ section, onClick, focusedMark }: InteractionMarkProps): JSX.Element =>
  <div id="interactionmarkerBox">
    {
      section.interactions.map((x, i) =>
        <TooltipHost
          key={i + focusedMark}
          delay={50}
          content={x.desc}
        >
          <div
            onClick={() => onClick(x.scene, x.id)}
            style={{ backgroundColor: section.colour + (x.complete ? "dd" : "00") }}
            id={x.complete ? "" : "notDone"}
            className={"marker" + ((focusedMark === x.id) ? " focus" : "")}
            key={i}>
          </div>
        </TooltipHost>)
    }
  </div>;
export default InteractionMarker;
