import React from "react";
import { Entity } from "aframe-react";
import { Interactable } from "../../../interfaces/Interactable";
import { ITourScene } from "../../../interfaces/tour";

interface ImageLinkProps extends Interactable { imgSrc: string, linksTo: ITourScene};

class ImageLink extends React.Component<ImageLinkProps> {
  public constructor(props: ImageLinkProps) {
    super(props);
  }

  private handleClick = () => {
    this.props.onInteract(this.props.linksTo);
  }

  public render() {
    return (<Entity
      key={this.props.linksTo.title}
      primitive="a-image"
      class="interactive"
      id="transition-icon"
      position={this.props.position}
      src={"#" + this.props.imgSrc}
      event-set__1="_event: mouseup; scale: 1 1 1"
      event-set__2="_event: mouseenter; scale: 1.1 1.1 1.1"
      event-set__3="_event: mousedown; scale: .9 .9 .9"
      event-set__4="_event: mouseleave; scale: 1 1 1"
      look-at="[camera]"
      events={{ click: this.handleClick }}
    >
      <Entity
        primitive="a-text"
        value={this.props.linksTo.section.title}
        align="center"
        position="0 -0.65 0"
        width="3.3"
      />
    </Entity>
    );
  }
}

export { ImageLink }; export type { ImageLinkProps };
