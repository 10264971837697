import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./i18n";
import { Scene } from "aframe-react";
import "./aframe-components/preloader";
import { PreImportAssets } from "./interfaces/tourData";
import IntroVideo from "./assets/videos/Intro.mp4";
import IntroImg from "./assets/images/Intro.png";


ReactDOM.render(
  <React.StrictMode>
    <Scene preloader="target: #preloader-modal; label: #preloader-modal .progress-label;" cursor="rayOrigin: mouse" raycaster="objects: .interactive" vr-mode-ui="enabled: false">
      <a-assets timeout="60000">
        <img id="introBlur" src={IntroImg}/>
        <video id="intro" loop={false} src={IntroVideo} />
        {
          PreImportAssets.map((x) => {
            return <img key={x} id={x} src={x} />;
          })
        }
      </a-assets>
      <App />
    </Scene>
  </React.StrictMode>,
  document.getElementById("root")
);
