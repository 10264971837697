/* eslint-disable no-unused-vars */
import React from "react";
import HeaderButton from "./HeaderButton";
import "./HeaderButtonBar.scss";
import { HeaderButtonBarProps, HeaderButtonType, ButtonTooltips, ButtonImgs } from "./HeaderButtonBar.types";

const HeaderButtonBar = ({ onClick }: HeaderButtonBarProps): JSX.Element =>
  <div id="headerButtonBar">
    {
      Object.values(HeaderButtonType).map(x => {
        if (isNaN(Number(x)) === false) {
          return <HeaderButton
            key={x}
            onClick={onClick}
            tooltipText={ButtonTooltips.get(x as HeaderButtonType)}
            type={x as HeaderButtonType}
            imgSrc={ButtonImgs.get(x as HeaderButtonType)}
          />;
        } else {
          return null;
        }
      })
    }
  </div>;

export default HeaderButtonBar;
