import HelpIcon from "../../assets/images/buttons/help.png";
import Home from "../../assets/images/buttons/home.png";
import ZoomIn from "../../assets/images/buttons/zoomIn.png";
import ZoomOut from "../../assets/images/buttons/zoomOut.png";
import ZoomReset from "../../assets/images/buttons/zoomReset.png";

export enum HeaderButtonType {
  ZOOM_IN,
  ZOOM_OUT,
  RESET_ZOOM,
  HOME,
  HELP
}

export const ButtonImgs = new Map<HeaderButtonType, string>([
  [HeaderButtonType.ZOOM_IN, ZoomIn],
  [HeaderButtonType.ZOOM_OUT, ZoomOut],
  [HeaderButtonType.RESET_ZOOM, ZoomReset],
  [HeaderButtonType.HOME, Home],
  [HeaderButtonType.HELP, HelpIcon]
]);

export const ButtonTooltips = new Map<HeaderButtonType, string>([
  [HeaderButtonType.ZOOM_IN, "Zoom in"],
  [HeaderButtonType.ZOOM_OUT, "Zoom out"],
  [HeaderButtonType.RESET_ZOOM, "Zoom reset"],
  [HeaderButtonType.HOME, "Return to start"],
  [HeaderButtonType.HELP, "Show help"]
]);

export interface HeaderButtonBarProps {
  onClick: (type: HeaderButtonType) => void;
}
