import React from "react";
import { HeaderButtonType } from "./HeaderButtonBar.types";
import { TooltipHost, ITooltipHostStyles } from "@fluentui/react/lib/Tooltip";
import "./HeaderButton.scss";
import { useId, useConst } from "@fluentui/react-hooks";

interface HeaderButtonProps {
  imgSrc: string;
  type: HeaderButtonType;
  tooltipText: string;
  onClick: (type: HeaderButtonType) => void;
}

const HeaderButton = ({ imgSrc, type, onClick, tooltipText }: HeaderButtonProps): JSX.Element => {
  const tooltipId = useId("tooltip");
  const buttonId = useId(tooltipText);
  const calloutProps = useConst({
    gapSpace: 0
  });

  const inlineBlockStyle: Partial<ITooltipHostStyles> = {
    root: {
      position: "relative",
      display: "inline-block",
      height: "52px"
    }
  };

  return (
    <TooltipHost
      content={tooltipText}
      id={tooltipId}
      styles={inlineBlockStyle}
      calloutProps={calloutProps}
      delay={50}
    >
      <img id={buttonId} onClick={() => onClick(type)} src={imgSrc} className="headerButton" />
    </TooltipHost>);
};

export default HeaderButton;
