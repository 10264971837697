import { Interactable } from "./Interactable";
import { mapProps } from "../components/Map/mapProps";

export interface IInteractions {
  id: string;
  complete: boolean;
  scene: ITourScene;
  desc: string;
  position: string;
}

export interface ISection {
  id: number;
  title: string;
  colour: string;
  map: (props: mapProps | undefined) => JSX.Element;
  interactionCount: number;
  interactionsComplete: number;
  interactions: IInteractions[];
};

export interface ITransitionIcon {
  position: string;
  linksTo: ITourScene;
};

export interface IVideoIcon {
  position: string;
  rotation: string;
  avLink: string;
  descLink: string;
  src: string;
  key: string;
};

export interface ITourScene {
  title: string;
  section: ISection;
  source: string;
  transitions?: ITransitionIcon[];
  interactions?: Interactable[],
  videos?: IVideoIcon[];
  rotOffset?: number;
};

export interface ITourData {
  startScene: ITourScene,
  sections: ISection[];
  scenes: ITourScene[];
}

export function countInteractions(tour: ITourData): void {
  tour.sections.forEach((section, i) => {
    tour.scenes.forEach((scene) => {
      if (scene.section === section && scene.interactions) {
        tour.sections[i].interactionCount += scene.interactions.length;
        scene.interactions.forEach((interaction) => {
          tour.sections[i].interactions.push({
            id: interaction.id,
            complete: false,
            scene: scene,
            desc: interaction.type,
            position: interaction.position
          });
        });
      }
    });
  });
}
