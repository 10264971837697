/* eslint-disable */
// @ts-nocheck
// import * as THREE from "types/three";

AFRAME.registerComponent("video-icon", {
  schema: {
    width: { type: "number", default: 1 },
    height: { type: "number", default: 1 },
    desc_link: { type: "string" },
    av_link: { type: "string" },
    play_link: { type: "string" }
  },
  multiple: true,
  init: function() {
    var data = this.data;
    var el = this.el;

    // Create description planes
    this.desc_geometry = new THREE.PlaneBufferGeometry(this.data.width, this.data.height);
    const desc_texture = new THREE.TextureLoader().load(this.data.desc_link);
    this.desc_material = new THREE.MeshBasicMaterial({ map: desc_texture, transparent: true });
    this.desc_mesh = new THREE.Mesh(this.desc_geometry, this.desc_material);
    el.setObject3D("desc_mesh", this.desc_mesh);

    // Create avatar plane
    this.av_geometry = new THREE.PlaneBufferGeometry(0.5, 0.5);
    const av_texture = new THREE.TextureLoader().load(this.data.av_link);
    this.av_material = new THREE.MeshBasicMaterial({ map: av_texture, transparent: true });
    this.av_mesh = new THREE.Mesh(this.av_geometry, this.av_material);
    this.av_mesh.position.set(0, 0.22, 0.01);
    el.setObject3D("av_mesh", this.av_mesh);

    // Create play plane
    this.play_geometry = new THREE.PlaneBufferGeometry(0.5, 0.5);
    const play_texture = new THREE.TextureLoader().load(this.data.play_link);
    this.play_material = new THREE.MeshBasicMaterial({ map: play_texture, transparent: true });
    this.play_mesh = new THREE.Mesh(this.play_geometry, this.play_material);
    this.play_mesh.position.set(0, 0.22, 0.015);
    this.play_mesh.visible = false;
    el.setObject3D("play_mesh", this.play_mesh);

    el.addEventListener("mouseenter", function (event) {
      el.getObject3D("play_mesh").visible = true;
      el.getObject3D("play_mesh").scale.set(1.12, 1.12, 1.12);
      el.getObject3D("av_mesh").scale.set(1.12, 1.12, 1.12);
    });

    el.addEventListener("mouseleave", function (event) {
      el.getObject3D("play_mesh").visible = false;
      el.getObject3D("play_mesh").scale.set(1, 1, 1);
      el.getObject3D("av_mesh").scale.set(1, 1, 1);
    });

    el.addEventListener("mousedown", function (event) {
      el.getObject3D("desc_mesh").scale.set(0.92, 0.92, 0.92);
    });

    el.addEventListener("mouseup", function (event) {
      el.getObject3D("desc_mesh").scale.set(1, 1, 1);
    });
  },
  update: function(oldData) {

  },
  remove: function() {
    this.el.removeObject3D("desc_mesh");
  }
});
