// @ts-nocheck
import React from "react";
import "./VideoIcon.scss";
import { Keyframes } from "react-spring/renderprops";
import ReactPlayer from "react-player";
import closeIcon from "../../../assets/images/close-icon.png";

type IVideoIconProps = {
  header: string,
  content: string,
  colour: string,
  src: string
  onDissmiss?: () => void | null
};

type IVideoPlayerState = {
  src: string,
  playerOpen: string,
  isOpen: boolean
}

const OuterContainer = Keyframes.Spring({
  open: { height: "100vh", width: "100vw", zIndex: 1005, pointerEvents: "auto", backgroundColor: "#00000088", opacity: 1, position: "absolute" },
  closed: { height: "100vh", width: "100vw", zIndex: 1005, pointerEvents: "none", backgroundColor: "#00000000", opacity: 0, position: "absolute" }
});

let VideoContainer = Keyframes.Spring({
  open: { height: 540, width: 960, left: 55, top: 45, zIndex: 1005, backgroundColor: "#000000FF", opacity: 1, position: "absolute" },
  closed: { height: 0, width: 0, left: 0, top: 0, zIndex: 1005, backgroundColor: "#00000000", opacity: 0, position: "absolute" }
});

class VideoPlayerTest extends React.Component<IVideoIconProps, IVideoPlayerState> {
  mouseX: number = 0;
  mouseY: number = 0;
  player: any;

  public constructor(props: IVideoIconProps) {
    super(props);
    this.state = { playerOpen: "closed", isOpen: false, src: "none" };
  }

  public componentDidMount() {
    document.addEventListener("mousemove", this.onMouseMove);
  }

  public openPlayer = (src: string) => {
    this.updateVideoKeyframes();
    this.setState({ }, () => {
      this.setState({
        src: src,
        isOpen: true,
        playerOpen: "open"
      });
    });
  }

  public closePlayer = () => {
    if (this.props.onDissmiss !== null) {
      this.props.onDissmiss();
    }
    this.player.seekTo(0);
    this.setState({}, () => {
      this.setState({
        isOpen: false,
        playerOpen: "closed"
      });
    });
  }

  private updateVideoKeyframes() {
    const clientX = window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

    const clientY = window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

    const mouseX = this.mouseX;
    const mouseY = this.mouseY;

    const width = (clientX / 2) - 960 / 2;
    const height = (clientY / 2) - 540 / 1.5;

    VideoContainer = Keyframes.Spring({
      open: { height: 540, width: 960, left: width, top: height, zIndex: 105, backgroundColor: "#FF000000", opacity: 1, position: "absolute" },
      closed: { height: 0, width: 0, left: mouseX, top: mouseY, zIndex: 105, backgroundColor: "#00000000", opacity: 0, position: "absolute" }
    });
  }

  private onMouseMove = (event: MouseEvent) => {
    this.mouseX = event.clientX;
    this.mouseY = event.clientY;
  };

  private ref = (player: any) => {
    this.player = player;
  };

  public render() {
    return (
      // @ts-ignore
      <OuterContainer state={this.state.playerOpen}>
        {
          (stylesA: React.CSSProperties) =>
            <div style={stylesA} onClick={this.closePlayer}>
              <VideoContainer state={this.state.playerOpen}>
                {
                  (styles: React.CSSProperties) =>
                    <div style={styles} id="videoDiv">
                      <div id="videoBorder" style={{ background: this.props.colour + "aa" }}>
                        <ReactPlayer
                          ref={this.ref}
                          playing={this.state.isOpen}
                          controls={true}
                          className='reactPlayer'
                          url={this.props.src}
                          width="100%"
                          height="100%"
                        />
                      </div>
                      <div id ="videoCloseButton" style={{ background: this.props.colour + "aa" }}>
                        <img id="videoCloseIcon" src={closeIcon} />
                      </div>
                      <div id="videoDisplayHeader" style={{ background: this.props.colour + "aa" }}>{this.props.header}</div>
                      <div id="videoDisplayContent">{this.props.content}</div>
                    </div>
                }
              </VideoContainer>
            </div>
        }
      </OuterContainer>
    );
  }
}

export default VideoPlayerTest;
