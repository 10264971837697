import { countInteractions, ISection, ITourData, ITourScene } from "./tour";
import { ImageLinkProps } from "../components/AFrame/ImageLink/ImageLink";
import { ModelProps } from "../components/AFrame/Model/Model";
import { TextBoxProps } from "../components/AFrame/TextBox/TextBox";
import { ImageDisplayProps } from "../components/AFrame/ImageDisplay/ImageDisplay";
import { VideoIconProps } from "../components/AFrame/VideoIcon/VideoIcon";
import { ImageCarouselProps } from "../components/AFrame/ImageCarousel/ImageCarousel";

// Art Imports
import LectureHallExitDoors from "../assets/images/lectureHallExitDoors.jpg";
import DirectionsSign from "../assets/images/directionsSign.jpg";
import TillAreaQueue from "../assets/images/tillAreaQueue.jpg";
import LargeOpenSpace from "../assets/images/largeOpenSpace.jpg";
import TwoWayNavigation from "../assets/images/twoWayNavigation.jpg";
import RoomNumber from "../assets/images/RoomNumber.jpg";
import CanteenIcon from "../assets/images/canteenIcon.png";
import LectureHallIcon from "../assets/images/lectureHallIcon.png";
import StudentAreaIcon from "../assets/images/studentAreaIcon.png";
import LogoTextModel from "../assets/models/textLogo.glb";
import StudentAreaBins from "../assets/images/studentAreaBins.jpg";
import CCS1 from "../assets/images/CanteenSignCarousel1.jpg";
import CCS2 from "../assets/images/CanteenSignCarousel2.jpg";
import CCS3 from "../assets/images/CanteenSignCarousel3.jpg";
import CCS4 from "../assets/images/CanteenSignCarousel4.jpg";
import CCS5 from "../assets/images/CanteenSignCarousel5.jpg";
import Events1 from "../assets/images/events1.jpg";
import Events2 from "../assets/images/events2.jpg";
import Events3 from "../assets/images/events3.jpg";

// 360 Imports
import Canteen1 from "../assets/images/360/Canteen_1.jpg";
import Canteen2 from "../assets/images/360/Canteen_2.jpg";
import Canteen3 from "../assets/images/360/Canteen_3.jpg";
import Canteen4 from "../assets/images/360/Canteen_4.jpg";
import Canteen5 from "../assets/images/360/Canteen_Outside.jpg";
import LectureHall1 from "../assets/images/360/LectureHall_1.jpg";
import LectureHall2 from "../assets/images/360/LectureHall_2.jpg";
import StudentArea1 from "../assets/images/360/StudentArea_1.jpg";
import StudentArea2 from "../assets/images/360/StudentArea_2.jpg";
import StudentArea3 from "../assets/images/360/StudentArea_3.jpg";
import StudentArea4 from "../assets/images/360/StudentArea_4.jpg";
import StudentArea5 from "../assets/images/360/StudentArea_5.jpg";
import StudentArea6 from "../assets/images/360/StudentArea_6.jpg";
import StudentArea7 from "../assets/images/360/StudentArea_7.jpg";
import IntroBlur from "../assets/images/360/Intro_Blur.jpg";

// Maps
import CanteenMap from "../components/Map/CanteenMap";
import LectureHallMap from "../components/Map/LectureHallMap";
import StudentAreaMap from "../components/Map/StudentAreaMap";

import { nanoid } from "nanoid";

// Const 360 Preimports
export const PreImportAssets: string[] = [
  CanteenIcon,
  LectureHallIcon,
  StudentAreaIcon,
  IntroBlur,
  Canteen1,
  Canteen2,
  Canteen3,
  Canteen4,
  Canteen5,
  LectureHall1,
  LectureHall2,
  StudentArea1,
  StudentArea2,
  StudentArea3,
  StudentArea4,
  StudentArea5,
  StudentArea6,
  StudentArea7,
  LectureHallExitDoors,
  DirectionsSign,
  TillAreaQueue,
  LargeOpenSpace,
  TwoWayNavigation,
  RoomNumber,
  StudentAreaBins,
  CCS1,
  CCS2,
  CCS3,
  CCS4,
  CCS5,
  Events1,
  Events2,
  Events3
];

// Tour Sections
const lectureHall: ISection = { id: 1, title: "Lecture Hall", colour: "#30a3dc", map: LectureHallMap, interactionCount: 0, interactionsComplete: 0, interactions: [] };
const canteen: ISection = { id: 2, title: "Canteen", colour: "#8bc541", map: CanteenMap, interactionCount: 0, interactionsComplete: 0, interactions: [] };
const studentArea: ISection = { id: 3, title: "Student Area", colour: "#ee3e76", map: StudentAreaMap, interactionCount: 0, interactionsComplete: 0, interactions: [] };

// Scenes
const introduction: ITourScene = { title: "Start Scene", section: null, source: IntroBlur };
const SeatingFront: ITourScene = { title: "Seating Area - Front", section: canteen, source: Canteen1, rotOffset: -90 };
const SeatingBack: ITourScene = { title: "Seating Area - Back", section: canteen, source: Canteen2 };
const Deli: ITourScene = { title: "Deli", section: canteen, source: Canteen3, rotOffset: -90 };
const CanteenOutside: ITourScene = { title: "Canteen Entrance", section: canteen, source: Canteen5, rotOffset: 170 };
const SeatingSide: ITourScene = { title: "Seating Area - Side", section: canteen, source: Canteen4, rotOffset: 90 };
const lectureHallB: ITourScene = { title: "Seating Area - Front", section: lectureHall, source: LectureHall2 };
const lectureHallA: ITourScene = { title: "Seating Area - Back", section: lectureHall, source: LectureHall1, rotOffset: 30 };
const Entrance: ITourScene = { title: "Entrance", section: studentArea, source: StudentArea1, rotOffset: 180 };
const Foyer: ITourScene = { title: "Foyer", section: studentArea, source: StudentArea4, rotOffset: 0 };
const Hallway: ITourScene = { title: "Hallway", section: studentArea, source: StudentArea3 };
const FoyerSeating: ITourScene = { title: "Foyer Seating", section: studentArea, source: StudentArea2, rotOffset: 200 };
const HelpDesk: ITourScene = { title: "Help Desk", section: studentArea, source: StudentArea5 };
const SUOffice: ITourScene = { title: "SU Offices", section: studentArea, source: StudentArea6 };
const EntranceHallway: ITourScene = { title: "Entrance Hallway", section: studentArea, source: StudentArea7, rotOffset: 120 };

// Add Transitions
CanteenOutside.transitions = [
  { position: "-1.243 1.6 1.660", linksTo: SeatingFront }
];
SeatingFront.transitions = [
  { position: "5.488 1.264 1.781", linksTo: SeatingSide },
  { position: "6.341 1.264 -3.993", linksTo: Deli },
  { position: "-0.407 1.6 5.170", linksTo: CanteenOutside }
];
SeatingBack.transitions = [{ position: "-5.038 1.418 -0.023", linksTo: SeatingSide }];
Deli.transitions = [{ position: "-4.164 1.718 -0.223", linksTo: SeatingFront }];
SeatingSide.transitions = [
  { position: "-4.164 1.718 -0.223", linksTo: SeatingFront },
  { position: "4.0 1.433 -0.026", linksTo: SeatingBack }
];
lectureHallA.transitions = [{ position: "-0.169 -0.314 4.157", linksTo: lectureHallB }];
lectureHallB.transitions = [{ position: "0.440 3.073 -5.173", linksTo: lectureHallA }];
Entrance.transitions = [
  { position: "-2.595 3.286 5.122", linksTo: FoyerSeating },
  { position: "-4.067 1.747 -1.722", linksTo: EntranceHallway }
];
Foyer.transitions = [
  { position: "-1.004 1.213 4.026", linksTo: FoyerSeating },
  { position: "4.580 1.497 0.083", linksTo: HelpDesk },
  { position: "-6.246 1.497 -0.621", linksTo: Hallway }
];
Hallway.transitions = [{ position: "-2.615 1.497 1.534", linksTo: Foyer }];
FoyerSeating.transitions = [
  { position: "-2.201 0.355 -4.304", linksTo: Entrance },
  { position: "0.642 1.591 3.236", linksTo: Foyer }
];
HelpDesk.transitions = [
  { position: "-0.881 1.591 5.538", linksTo: Foyer },
  { position: "-4.983 1.591 -3.643", linksTo: SUOffice }
];
SUOffice.transitions = [{ position: "4.878 1.591 1.433", linksTo: HelpDesk }];
EntranceHallway.transitions = [{ position: "-1.555 1.591 3.367", linksTo: Entrance }];

// Add interactions
introduction.interactions = [
  { id: nanoid(), type: "LINK", imgSrc: LectureHallIcon, linksTo: lectureHallB, position: "-1.75 1.4 -2" } as ImageLinkProps,
  { id: nanoid(), type: "LINK", imgSrc: CanteenIcon, linksTo: CanteenOutside, position: "0 1.4 -2" } as ImageLinkProps,
  { id: nanoid(), type: "LINK", imgSrc: StudentAreaIcon, linksTo: EntranceHallway, position: "1.75 1.4 -2" } as ImageLinkProps,
  { id: nanoid(), type: "MODEL", modelSrc: LogoTextModel, scale: ".5 .5 .5", rotation: "0 0 0", position: "-0.183 3.191 -3.460" } as ModelProps
];

lectureHallA.interactions = [
  { id: nanoid(), type: "TEXT", content: "Strong lights are used to brighten up the lecture hall. They may be dimmed for a time and then return to full brightness which may dazzle you. They may also emit a humming sound. ", header: "Bright Lights In Lecture Hall", colour: "#30a3dc", position: "-3.912 1.668 -0.306" } as TextBoxProps,
  { id: nanoid(), type: "IMAGE", imgSrc: LectureHallExitDoors, content: "In some lecture halls you may be required to enter and exit through the same doors. Try to be mindful of people coming in the opposite direction. Doors may squeak as they open and bang as they close. Push bars on a door may rattle as they move. ", header: "Exit doors at the back of the lecture hall", colour: "#30a3dc", position: "3.249 1.870 -0.914" } as ImageDisplayProps
];

lectureHallB.interactions = [
  { id: nanoid(), type: "VIDEO", src: "https://vimeo.com/562284111/07d62e77ca", content: "Overhead projectors are used to display class content to the front of the room. Images may flicker  when it is turned on. The room lights might be dimmed while the projector is being used and then return to full brightness once the projector is turned off.", header: "Overhead Projectors", position: "3.408 3.680 0.101", colour: "#30a3dc" } as VideoIconProps,
  { id: nanoid(), type: "VIDEO", src: "https://vimeo.com/562284088/0f8edae886", content: "As students enter and leave the lecture hall, they will fold and unfold the chairs which may squeak or clap.", header: "Possible Noisy Chairs", position: "1 1 -1.75", colour: "#30a3dc" } as VideoIconProps
];

SeatingFront.interactions = [
  { id: nanoid(), type: "VIDEO", src: "https://vimeo.com/562284272/164ac567a6", content: "The chairs in the canteen are arranged at a distance of 2 meters as per the COVID regulations. People will move tables and chairs in to sit down. There may be a bit of noise as people drag and lift the furniture into position. ", header: "Seats at the tables are separated at 2 meters", position: "-0.619 1.0 -2.471", colour: "#8bc541" } as VideoIconProps,
  { id: nanoid(), type: "VIDEO", src: "https://vimeo.com/562284141/a39cc52395", content: "Barriers are used to allow people to enter and exit the canteen area. They help to manage the amount of people in the canteen so that it doesn’t get too crowded. The turnstiles may thud and click and bang as they turn. That’s just the wheel in the turnstile turning each time someone passes through.", header: "There could be barriers to go through to get into the canteen.", position: "3.751 1.468 -4.070", colour: "#8bc541" } as VideoIconProps,
  { id: nanoid(), type: "CAROUSEL", imgArray: [CCS1, CCS2, CCS3, CCS4, CCS5], content: "Signage is used throughout the canteen to ensure that it doesn’t become too crowded. Staff may move signs during the day as one area fills up and another area needs to be used. The signs can also point to different locations of the canteen.", header: "Signage directs people to different parts of the canteen.", position: "3.025 1.642 2.129", colour: "#8bc541" } as ImageCarouselProps,
  { id: nanoid(), type: "VIDEO", src: "https://vimeo.com/562284290/87671989b4", content: "When people are finished their meals they are asked to return their trays to help keep the canteen tidy. The tray drop off area may be a bit messy. As the attendants clean away the crockery and plates and cups, you will hear much banging and clanging. You may also hear the dishwasher make a humming sound.", header: "Often there are noisy places to put your tray and empty dishes.", position: "2.180 1.568 3.856", colour: "#8bc541" } as VideoIconProps,
  { id: nanoid(), type: "VIDEO", src: "https://vimeo.com/562284203/d1623bfdad", content: "Please use these while you enter and leave the canteen.", header: "Hand Sanitizer stations are located throughout the canteen", position: "-1.250 1.568 3.3856", colour: "#8bc541" } as VideoIconProps
];

SeatingBack.interactions = [
  { id: nanoid(), type: "VIDEO", src: "https://vimeo.com/562284172/4bb3069972", content: "Coffee machines make a lot of noise as the coffee beans are ground up and the steamers heat up the milk", header: "Coffee machines can often be loud", position: "2.430 1.199 -6.377", colour: "#8bc541" } as VideoIconProps
];

SeatingSide.interactions = [
  { id: nanoid(), type: "VIDEO", src: "https://vimeo.com/562284228/c8865c645d", content: "Signage is used to guide people around the canteen safely and ensure social distancing. You may see some people observe the signage strictly and others who may not take much notice. However, the signage is a guide only so you should try to be mindful of your own space and ensure that you, and anyone nearby, have enough room to move and feel safe.", header: "Signage around the canteen for Covid 19 measures.", position: "1.899 0.276 -1.123", colour: "#8bc541" } as VideoIconProps
];

Deli.interactions = [
  { id: nanoid(), type: "IMAGE", imgSrc: TillAreaQueue, content: "When joining a queue it is best to find the till first and see which way people are standing and waiting. They may form a straight line to the left, the right or even right in front of the till. Some lines may be loose and unstructured. You can ask someone if they are in the queue to be sure that you’re not skipping the line.", header: "Queuing at the tills can be confusing", colour: "#8bc541", position: "-4.826 1.468 1.195" } as ImageDisplayProps,
  { id: nanoid(), type: "IMAGE", imgSrc: TwoWayNavigation, content: "Signage will indicate to you how you should navigate the canteen. Just follow the arrows in the direction they are pointing and stay reasonably within the lines.", header: "Two-way navigation in the canteen", colour: "#8bc541", position: "3.629 0.117 1.316" } as ImageDisplayProps
];

Entrance.interactions = [
  { id: nanoid(), type: "VIDEO", src: "https://vimeo.com/562283995/328bd0b6c1", content: "Student areas are often large with a lot of space for people to move around in. These areas can get busy.", header: "Large student areas with a lot of space for people to move around", position: "0-.706 2.092 3.222", colour: "#ee3e76" } as VideoIconProps
];

EntranceHallway.interactions = [
  { id: nanoid(), type: "CAROUSEL", imgArray: [Events1, Events2, Events3], content: "You will see many different types of posters displayed on walls in the canteen area. These are college advertising events that are happening.", header: "Posters are displayed on the walls to showcase current events", position: "-2.946 1.652 -0.537", colour: "#ee3e76" } as ImageCarouselProps,
  { id: nanoid(), type: "IMAGE", imgSrc: DirectionsSign, content: "You will see a lot of signage in the student areas. These messages are put up to help students find places or simply to locate information or support. ", header: "Signage points students in the direction of help and support.", colour: "#ee3e76", position: "-2.212 1.6 -3.686" } as ImageDisplayProps
];

Foyer.interactions = [
  { id: nanoid(), type: "IMAGE", imgSrc: LargeOpenSpace, content: "When you are in student areas, you may experience more noise and sound than you are used to. This is due to the acoustics of the room. It might sound overwhelming but it is just the sound travelling upward into the high ceilings.", header: "High ceilings in the student area", colour: "#ee3e76", position: "1.590 2.719 3.154" } as ImageDisplayProps,
  { id: nanoid(), type: "VIDEO", src: "https://vimeo.com/562284037/dad188068b", content: "Many of the seats used throughout the canteen are made from plastic. They can make a loud sound when they are pulled across the floor, or as students go to sit down or stand up", header: "Seating is often plastic chairs that may make a noise when they are pulled on the ground.", position: "-3.239 1 -4.482", colour: "#ee3e76" } as VideoIconProps,
  { id: nanoid(), type: "VIDEO", src: "https://vimeo.com/562283882/b4bc486cca", content: "Look for the large flags around the campus. They are there to show the various clubs and societies.", header: "Often large flags to show the clubs and societies.", position: "-4.842 2.633 -1.737", colour: "#ee3e76" } as VideoIconProps,
  { id: nanoid(), type: "IMAGE", imgSrc: StudentAreaBins, content: "The bin areas in the canteen are designed to improve recycling. You will see different bins coloured for different waste types.", header: "large bin areas", colour: "#ee3e76", position: "-2.690 1.083 -1.881" } as ImageDisplayProps
];

FoyerSeating.interactions = [
  { id: nanoid(), type: "VIDEO", src: "https://vimeo.com/562283972/302e8da1fb", content: "Strong lights are used to brighten up the student areas. They may be dimmed for a time and then return to full brightness which may dazzle you. They may also emit a humming sound. Some lights that are not working correctly may flicker for a time before being fixed.", header: "Fluorescent lights that are very bright can often be in the student area.", position: "2.692 2.358 -2.419", colour: "#ee3e76" } as VideoIconProps,
  { id: nanoid(), type: "TEXT", content: "Some areas of the student area may be brighter and lighter than others. The sun may shine through the window or you may see lots of activity outside. There is plenty of seating around the student area and you should be able to find an area that you think will be comfortable for you.", header: "Windows can often be large and let a lot of light in.", colour: "#ee3e76", position: "-0.267 1.752 -4.103" } as TextBoxProps,
  { id: nanoid(), type: "VIDEO", src: "https://vimeo.com/562284016/2851d2217a", content: "There are several areas for seating throughout the campus and in the student area. You are welcome to sit there if you wish. ", header: "Large seating areas for students.", position: "2.692 1.562 2.391", colour: "#ee3e76" } as VideoIconProps
];

HelpDesk.interactions = [
  { id: nanoid(), type: "VIDEO", src: "https://vimeo.com/562283902/898879142b", content: "You will find quiet pods throughout the building. These are areas which have been designed for autistic students to escape the hustle and bustle of campus life and allow them to focus on their work.", header: "There are often quiet areas for autistic students, such as quiet pods.", position: "3.545 1.2 -0.121", colour: "#ee3e76" } as VideoIconProps,
  { id: nanoid(), type: "VIDEO", src: "https://vimeo.com/562283950/2d0077f080", content: "You will notice that signs are bilingual and text is displayed in both English and Irish.", header: "Signage is often written in English and Irish", position: "-3.332 1.515 2.494", colour: "#ee3e76" } as VideoIconProps
];

SUOffice.interactions = [
  { id: nanoid(), type: "IMAGE", imgSrc: RoomNumber, content: "You will see that all the rooms have numbers on them to help you find your way around. Remember, many signs are also in Irish as well as English.", header: "Room numbers can be written on the door. Often also in Irish.", colour: "#ee3e76", position: "-1.375 1.6 -3.196" } as ImageDisplayProps,
  { id: nanoid(), type: "VIDEO", src: "https://vimeo.com/562283902/898879142b", content: "You will find quiet pods throughout the building. These are areas which have been designed for autistic students to escape the hustle and bustle of campus life and allow them to focus on their work.", header: "There are often quiet areas for autistic students, such as quiet pods.", position: "0.755 1.2 3.053", colour: "#ee3e76" } as VideoIconProps,
  { id: nanoid(), type: "VIDEO", src: "https://vimeo.com/562284065/1ff7ec87bf", content: "Here is a phone charging booth. You can connect your phone for a short while to recharge its battery if needed. You’ll find a range of different devices like this around campus.", header: "Different devices in the student area, such as a phone charging booth.", position: "3.058 1 3.283", colour: "#ee3e76" } as VideoIconProps
];

Hallway.interactions = [
  { id: nanoid(), type: "VIDEO", src: "https://vimeo.com/562283923/53ffd5f8a9", content: "There is plenty of seating around the student area  and you should be able to find an area that you think will be comfortable for you.", header: "Lots of comfortable seating areas", position: "-1.284 1.3 -3.894", colour: "#ee3e76" } as VideoIconProps
];

// Complete tour data
const tourData: ITourData = {
  startScene: introduction,
  sections: [
    lectureHall,
    canteen,
    studentArea
  ],
  scenes: [
    introduction,
    CanteenOutside,
    SeatingFront,
    SeatingSide,
    Deli,
    SeatingBack,
    lectureHallB,
    lectureHallA,
    EntranceHallway,
    Entrance,
    FoyerSeating,
    Foyer,
    Hallway,
    HelpDesk,
    SUOffice
  ]
};

countInteractions(tourData);

export default tourData;
