import React from "react";
import { ITourScene, ISection } from "../../interfaces/tour";
import logo from "../../assets/images/branding/logo_large.png";
import Sidebarcon from "../../assets/images/sidebarIcon.png";
import tourData from "../../interfaces/tourData";
import Map from "../Map/Map";
import "./SideMenu.scss";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface ISideMenuSmallButtonProps {
  scene: ITourScene;
  activeScene: ITourScene;
  onActiveSceneChanged(scene: ITourScene): void;
}

class SideMenuSmallButton extends React.Component<ISideMenuSmallButtonProps> {
  public constructor(props: ISideMenuSmallButtonProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  private onClick() {
    this.props.onActiveSceneChanged(this.props.scene);
  }

  public render() {
    const active: boolean = this.props.scene === this.props.activeScene;
    return (
      <li className={active ? "side-menu-button-small active" : "side-menu-button-small"}>
        <span className="buttonSmallText" onClick={this.onClick}>{this.props.scene.title}</span>
      </li>
    );
  }
}

interface ILargeButtonProps {
  section: ISection;
  activeScene: ITourScene;
  onActiveSceneChanged(scene: ITourScene): void;
}

interface ILargeButtonState {
  toggled: boolean;
}

class SideMenuLargeButton extends React.Component<ILargeButtonProps, ILargeButtonState> {
  public constructor(props: ILargeButtonProps) {
    super(props);
    this.state = { toggled: false };
    this.onClick = this.onClick.bind(this);
  }

  private onClick() {
    this.setState(prevState => ({
      toggled: !prevState.toggled
    }));
  }

  public render() {
    const active: boolean = this.props.section === this.props.activeScene.section;
    const scenes: ITourScene[] = Array.from(tourData.scenes.values()).filter(
      scene => scene.section === this.props.section);
    return (
      <div onClick={this.onClick}>
        <li className={active ? "side-menu-button active" : "side-menu-button"}>
          <span className="buttonText">{this.props.section.title}</span>
          <div className="rectangle" style={{ backgroundColor: this.props.section.colour }}></div>
        </li>

        {(this.state.toggled)
          ? <ul className="list-small">
            {scenes.map((scene) => (
              <SideMenuSmallButton
                key={scene.title}
                scene={scene}
                activeScene={this.props.activeScene}
                onActiveSceneChanged={this.props.onActiveSceneChanged} />
            ))}
          </ul>
          : null
        }

      </div>);
  }
}

interface ITourFooterProps extends RouteComponentProps {
  activeScene: ITourScene;
  onActiveSceneChanged(scene: ITourScene): void;
}

interface ISideMenuState {
  open: boolean;
}

class SideMenu extends React.Component<ITourFooterProps, ISideMenuState> {
  constructor(props: ITourFooterProps) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleReturnToIntro = () => { this.props.history.push("/"); };

  handleReturnToStartScene = () => { this.props.onActiveSceneChanged(tourData.scenes[0]); };

  handleActiveModuleChange = (scene: ITourScene) => { this.props.onActiveSceneChanged(scene); };

  private toggleSideMenu = () => {
    this.setState(prevState => ({
      open: !prevState.open
    }));
  };

  public render() {
    const activeScene = this.props.activeScene;
    const sections: ISection[] = Array.from(tourData.sections.values());
    return (
      <>
        <div className={this.state.open ? "side-menu" : "side-menu closed"} style={{ height: "100%" }}>
          <div className="side-menu-left" style={{ height: "100%", overflowY: "auto" }}>
            <img className="logo" src={logo} />
            <ul className="list" style={{ height: "auto", overflowY: "auto" }}>
              <span onClick={this.handleReturnToIntro} className="buttonText">Welcome Video</span>
              <span onClick={this.handleReturnToStartScene} className="buttonText">Start Scene</span>
              {sections.map((scene) => (
                <SideMenuLargeButton
                  key={scene.title}
                  section={scene}
                  activeScene={activeScene}
                  onActiveSceneChanged={this.handleActiveModuleChange} />
              ))}
            </ul>
          </div>
          <div style={{
            backgroundColor: activeScene.section ? activeScene.section.colour : "#30a3dc"
          }}
          className="side-menu-bar"
          onClick={this.toggleSideMenu}>
            <aside id="sidemenuTitle">MENU</aside>
            <img src={Sidebarcon} className={this.state.open ? "sidemenuBarIcon" : "sidemenuBarIcon closed"}/>
          </div>
        </div>
        {
          activeScene.section !== null
            ? <Map
              colour={activeScene.section.colour}
              open={this.state.open}
              map={activeScene.section.map}
              activeRoom={activeScene.title}
              onClick={this.handleActiveModuleChange}/>
            : null
        }
      </>
    );
  }
}

export default withRouter(SideMenu);
