import React from "react";
import { Entity } from "aframe-react";
import { Interactable } from "../../../interfaces/Interactable";
import "./ImageDisplay.scss";
import "aframe-html-shader";
import closeIcon from "../../../assets/images/close-icon.png";
import ImageModel from "../../../assets/models/image.glb";
import BaseIcon from "../BaseIcon/BaseIcon";

interface ImageDisplayProps extends Interactable {
  content: string,
  header: string,
  imgSrc?: string,
  imgArray?: string[],
  colour: string
};
interface ImageDisplayState {open: boolean};

class ImageDisplay extends React.Component<ImageDisplayProps, ImageDisplayState> {
  state: ImageDisplayState = {
    open: false
  };

  private onImageIconClicked = () => {
    this.props.onInteract(this.props.id);
    this.setState(prevState => ({
      open: !prevState.open
    }));
  }

  private onCloseClicked = () => {
    this.setState(prevState => ({
      open: !prevState.open
    }));
  }

  public render() {
    const open = this.state.open;
    return (
      <>
        {open
          ? <Entity position={this.props.position}>
            <div id="htmlElement" >
              <div id="imageContainer" style={{ background: this.props.colour + "aa" }}></div>
              <div id ="closeButton" style={{ background: this.props.colour + "aa" }}>
                <img id="closeIcon" src={closeIcon} />
              </div>
              <div id="imageDisplayHeader" style={{ background: this.props.colour + "aa" }}>{this.props.header}</div>
              <div id="imageDisplayContent">{this.props.content}</div>
            </div>

            <Entity
              look-at="[camera]"
              scale="6 6 6"
              geometry="primitive: plane; width: .9; height: .7;"
              material="shader: html; transparent: true; target: #htmlElement">
              <Entity
                primitive="a-image"
                src={this.props.imgSrc}
                width="1.6"
                height="0.9"
                scale=".5 .5 .5"
                position="-0.036 0.103 0.005"
              />
              <Entity
                class="interactive"
                position="0.420 0.311 -0.005"
                material="opacity: 0.0"
                geometry="primitive: plane; width: 0.05; height: 0.05;"
                events={{
                  click: this.onCloseClicked
                }} />
            </Entity>
          </Entity>
          : <BaseIcon {...this.props} modelSrc={ImageModel} onClick={this.onImageIconClicked} />
        }
      </>
    );
  }
}
export { ImageDisplay }; export type { ImageDisplayProps };
