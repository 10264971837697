import React from "react";
import { Entity } from "aframe-react";
import "./../../../aframe-components/look-at-component";
import WaypointMarkerImage from "../../../assets/images/waypoint.png";
import { ITourScene } from "../../../interfaces/tour";
require("aframe-event-set-component");

type ITransitionIconProps = {
    position: string,
    linksTo?: ITourScene,
    onIconClicked(scene: ITourScene): void
}

class TransitionIcon extends React.Component<ITransitionIconProps> {
  public constructor(props: ITransitionIconProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  private handleClick() {
    this.props.onIconClicked(this.props.linksTo);
  }

  public render() {
    return (
      <Entity
        key={this.props.linksTo.title}
        primitive="a-image"
        class="interactive"
        id="transition-icon"
        position={this.props.position}
        src={WaypointMarkerImage}
        scale=".85 .85 .85"
        event-set__1="_event: mouseup; scale: .85 .85 .85"
        event-set__2="_event: mouseenter; scale: .9 .9 .9"
        event-set__3="_event: mousedown; scale: .8 .8 .8"
        event-set__4="_event: mouseleave; scale: .85 .85 .85"
        look-at="[camera]"
        events={{ click: this.handleClick }}
      >
        <Entity
          primitive="a-text"
          value={this.props.linksTo.title}
          align="center"
          position="0 -0.65 0"
          width="4.1"
        />
      </Entity>
    );
  }
}

export default TransitionIcon;
