import React from "react";

export const HomeButton = (): JSX.Element => <a
  href="https://autismfriendlyhei.ie/resources/"
  style={{
    backgroundColor: "#9D405E",
    padding: "6px 15px",
    borderRadius: "3px",
    color: "white",
    textDecoration: "none",
    position: "absolute",
    top: 15,
    fontSize: "16px",
    left: 16,
    zIndex: 1037,
    cursor: "pointer"
  }}>Exit Virtual Tour</a>;
