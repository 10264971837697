import React from "react";
import "./App.scss";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Tour from "./scenes/tour/Tour";
import Intro from "./scenes/intro/Intro";
import { HomeButton } from "./components/HomeButton/HomeButton";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "a-assets": { timeout: string, children: any};
        }
    }
}

function App() {
  return (
    <BrowserRouter>
      <HomeButton />
      <Switch>
        <Route exact path="/">
          <Intro />
        </Route>
        <Route exact path="/tour">
          <Tour />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
