import * as React from "react";
import { ITourScene } from "../../interfaces/tour";
import "./Map.scss";
import { mapProps } from "./mapProps";

interface MapProps {
  colour: string
  open: boolean,
  activeRoom: string,
  map: (props: mapProps | undefined) => JSX.Element,
  onClick: (scene: ITourScene) => void
}

const Map = ({ activeRoom, map, colour, open, onClick }: MapProps): JSX.Element => {
  return (<div className={ "mapContainer" + (open ? " open" : " closed")}>
    {map({ activeRoom: activeRoom, colour: colour, onClick: onClick } as mapProps)}
  </div>);
};
export default Map;
