import React from "react";
import { Entity } from "aframe-react";
import { USER_HEIGHT, VIDEO_SPHERE_ID } from "../../../constants";

interface IVideoSphereProps {
    src: string;
    radius: number;
}

function VideoSphere(props:IVideoSphereProps) {
  return (
    <Entity
      primitive="a-videosphere"
      id={VIDEO_SPHERE_ID}
      src = {props.src}
      autoplay = {true}
      position={USER_HEIGHT}
      rotation="0 -90 0"
      radius ={props.radius}
    />
  );
}

export default VideoSphere;
