import React from "react";
import { Entity } from "aframe-react";
import { IdleAnimation, FocusedAnimationOpacity, FocusedAnimationScale } from "../../../animations";

interface BaseIconProps {
  position: string,
  modelSrc: string,
  colour: string,
  focused: boolean,
  onClick: () => void
}
const BaseIcon = ({ ...Props }: BaseIconProps): JSX.Element => <Entity
  key={Props.position + Props.focused}
  class="interactive"
  position={Props.position}
  gltf-model={Props.modelSrc}
  animation={IdleAnimation}
  scale=".5 .5 .5"
  event-set__1="_event: mouseup; scale: .5 .5 .5"
  event-set__2="_event: mouseenter; scale: .55 .55 .55"
  event-set__3="_event: mousedown; scale: .45 .45 .45"
  event-set__4="_event: mouseleave; scale: .5 .5 .5"
  events={{
    click: Props.onClick
  }}
>
  <Entity
    class="interactive"
    primitive="a-cylinder"
    animation={Props.focused ? FocusedAnimationOpacity : null}
    animation__2={Props.focused ? FocusedAnimationScale : null}
    material={"offset: -0.06 0; opacity: 0.75; color: " + Props.colour}
    geometry="segmentsHeight: 2; segmentsRadial: 64" rotation="0 90 -90"
    scale="0.57 0.06 0.57"
  />
</Entity>;

export default BaseIcon;
