import React from "react";
import VideoSphere from "../../components/AFrame/VideoSphere/VideoSphere";
import Camera from "../../components/AFrame/Camera/Camera";
import { ISection, ITourData, ITourScene, ITransitionIcon } from "../../interfaces/tour";
import tourData from "../../interfaces/tourData";
import SideMenu from "../../components/SideMenu/SideMenu";
import { InteractableObjects } from "../../interfaces/Interactable";
import TransitionIcon from "../../components/AFrame/TransitionIcon/TransitionIcon";
import { ImageLink, ImageLinkProps } from "../../components/AFrame/ImageLink/ImageLink";
import { Model, ModelProps } from "../../components/AFrame/Model/Model";
import { TextBox, TextBoxProps } from "../../components/AFrame/TextBox/TextBox";
import { ImageDisplay, ImageDisplayProps } from "../../components/AFrame/ImageDisplay/ImageDisplay";
import VideoPlayer from "../../components/AFrame/VideoIcon/VideoPlayer";
import VideoIcon, { VideoIconProps } from "../../components/AFrame/VideoIcon/VideoIcon";
import { ImageCarousel, ImageCarouselProps } from "../../components/AFrame/ImageCarousel/ImageCarousel";
import InteractionMarker from "../../components/InteractionMarker/InteractionMarker";
import EffectsSphere from "../../components/AFrame/EffectSphere/EffectsSphere";
import { BrightenScene } from "../../effects";
import HeaderButtonBar from "../../components/HeaderButtonBar/HeaderButtonBar";
import { HeaderButtonType } from "../../components/HeaderButtonBar/HeaderButtonBar.types";
import VideoPlayerTest from "../../components/AFrame/VideoIcon/VideoPlayerTest";

interface IModelViewerProps {}

interface IModelViewerState {
  activeScene: ITourScene;
  activeSection: ISection;
  activeTourData: ITourData;
  foundInteractions: string[];
  focusedInteraction: string;
}

class Tour extends React.Component<IModelViewerProps, IModelViewerState> {
  videoPlayer: React.RefObject<VideoPlayer> = React.createRef<VideoPlayer>();
  videoPlayerTest: React.RefObject<VideoPlayerTest> = React.createRef<VideoPlayerTest>();

  state: IModelViewerState = {
    activeTourData: tourData,
    activeScene: tourData.startScene,
    activeSection: tourData.startScene.section,
    foundInteractions: [],
    focusedInteraction: null
  }

  componentDidMount() {
    const tourDataNew: ITourData = tourData;
    this.setState({
      activeTourData: tourDataNew,
      activeScene: tourDataNew.startScene,
      activeSection: tourDataNew.startScene.section
    });
  }

  stopVideos() {
    // @ts-ignore
    document.querySelectorAll("video").forEach(vid => { vid.currentTime = 0; });
    // @ts-ignore
    document.querySelectorAll("video").forEach(vid => vid.pause());
  }

  playVideo(id: string) {
    const video: Element = document.getElementById(id);
    if (video && id.endsWith(".mp4")) {
      // @ts-ignore
      video.play();
    }
  }

  handleActiveSceneChange = (scene: ITourScene) => {
    this.stopVideos();
    this.setState({
      activeScene: scene,
      activeSection: scene.section
    }, () => {
      this.playVideo(scene.source);
      const camera = document.querySelector("a-camera");
      camera.components["look-controls"].yawObject.rotation.y = 0;
      camera.components["look-controls"].pitchObject.rotation.x = 0;
    });
  }

  handleActiveSectionChange = (section: ISection) => {
    this.stopVideos();
    const newScene = Array.from(tourData.scenes.values()).filter(
      scene => scene.section === section
    );
    this.handleActiveSceneChange(newScene[0]);
  }

  public handleTransitionIconClicked = (scene: ITourScene) => {
    this.handleActiveSceneChange(scene);
  }

  private handleInteractionMarkerClicked = (scene: ITourScene, id: string) => {
    this.stopVideos();
    this.setState({
      activeScene: scene,
      activeSection: scene.section
    }, () => {
      this.playVideo(scene.source);
    });
    this.setState({ focusedInteraction: id });
  }

  private handleInteractionIconClicked = (interactionId: string, effect: string) => {
    if (!this.state.foundInteractions.includes(interactionId)) {
      const newTourData = this.state.activeTourData;
      newTourData.sections.find(s => s === this.state.activeSection)
        .interactions.find(i => i.id === interactionId).complete = true;

      this.setState({
        foundInteractions: [...this.state.foundInteractions, interactionId],
        activeTourData: newTourData
      });
    }

    if (effect === BrightenScene) {
      document.querySelector("#EffectsSphere").emit(BrightenScene);
    }
  }

  private handleHeaderButtonClicked = (type: HeaderButtonType): void => {
    const camera = document.getElementById("camera");
    let finalZoom = 0;
    const cam = camera.getAttribute("camera");

    switch (type) {
    case HeaderButtonType.ZOOM_IN:
      // @ts-ignore
      finalZoom = (cam.fov - 5);
      if (finalZoom < 40) {
        finalZoom = 40;
      }
      // @ts-ignore
      cam.fov = finalZoom;
      camera.setAttribute("camera", cam);
      break;
    case HeaderButtonType.ZOOM_OUT:
      // @ts-ignore
      finalZoom = (cam.fov + 5);
      if (finalZoom > 100) {
        finalZoom = 100;
      }
      // @ts-ignore
      cam.fov = finalZoom;
      camera.setAttribute("camera", cam);
      break;
    case HeaderButtonType.RESET_ZOOM:
      // @ts-ignore
      cam.fov = 80;
      camera.setAttribute("camera", cam);
      break;
    case HeaderButtonType.HOME:
      this.handleTransitionIconClicked(tourData.scenes[0]);
      break;
    case HeaderButtonType.HELP:
      this.videoPlayerTest.current.openPlayer("https://vimeo.com/577720402/4c37a33d22");
      break;
    }
  };

  render() {
    const activeScene = this.state.activeScene;
    const activeSection = this.state.activeSection;
    const transitions: ITransitionIcon[] = activeScene.transitions;

    return (
      <>
        <HeaderButtonBar onClick={this.handleHeaderButtonClicked} />
        <SideMenu
          activeScene={this.state.activeScene}
          onActiveSceneChanged={this.handleActiveSceneChange}
        />
        {
          (activeSection !== null && activeSection !== undefined)
            ? <InteractionMarker
              onClick={this.handleInteractionMarkerClicked}
              section={activeSection}
              focusedMark={this.state.focusedInteraction}
            />
            : null
        }
        <EffectsSphere />
        <Camera offsetRot={activeScene.rotOffset}/>
        {
          transitions?.map(t =>
            <TransitionIcon
              key={t.linksTo.title} {...t} onIconClicked={this.handleTransitionIconClicked}
            />)
        }
        {
          activeScene.interactions?.map(i => {
            switch (i.type) {
            case InteractableObjects.LINK:
              return <ImageLink {...i as ImageLinkProps}
                onInteract={this.handleTransitionIconClicked}
                focused={i.id === this.state.focusedInteraction}/>;
            case InteractableObjects.TEXT:
              return <TextBox {...i as TextBoxProps}
                onInteract={this.handleInteractionIconClicked}
                focused={i.id === this.state.focusedInteraction}/>;
            case InteractableObjects.VIDEO:
              return <VideoIcon {...i as VideoIconProps}
                onInteract={this.handleInteractionIconClicked}
                focused={i.id === this.state.focusedInteraction}/>;
            case InteractableObjects.IMAGE:
              return <ImageDisplay {...i as ImageDisplayProps}
                onInteract={this.handleInteractionIconClicked}
                focused={i.id === this.state.focusedInteraction}/>;
            case InteractableObjects.MODEL:
              return <Model {...i as ModelProps}
                focused={i.id === this.state.focusedInteraction}/>;
            case InteractableObjects.CAROUSEL:
              return <ImageCarousel {...i as ImageCarouselProps}
                onInteract={this.handleInteractionIconClicked}
                focused={i.id === this.state.focusedInteraction}/>;
            default:
              break;
            }
            return null;
          })
        }
        <VideoSphere src={"#" + activeScene.source} radius={500}/>
        <VideoPlayerTest
          onDissmiss={null}
          header={"Navigation Instruction Video"}
          content={"Please watch this video to learn how to navigative and explore this 360 Virtual Tour. You can access this video anytime by clicking the Show help button on the top right of the screen"}
          colour={activeSection ? activeSection.colour : "#30a3dc"}
          src={"https://vimeo.com/577720402/4c37a33d22"}
          ref={this.videoPlayerTest}/>
      </>
    );
  }
}

export default Tour;
