import { ITourScene } from "./tour";

export enum InteractableObjects {
  TEXT = "TEXT",
  IMAGE = "IMAGE",
  CAROUSEL = "CAROUSEL",
  VIDEO = "VIDEO",
  LINK = "LINK",
  MODEL = "MODEL"
}

export interface Interactable {
  id: string,
  type: InteractableObjects,
  onInteract?(arg: ITourScene | string, data?: string): void,
  position: string,
  focused: boolean
}
